<ng-container *ngIf="inputModel != null">
         <!--[cdkDragDisabled]="inputIsReadOnlyMode"
         cdkDragBoundary=".selected-flow-objects"
         (cdkDragStarted)="dragStarted($event)"-->
    <div class="selected-grouping {{GroupingTypeClassName.get(+inputModel.typeId)}}">
        <ng-container *ngFor="let groupingColumn of inputSelectedGroupingColumns">
            <div *ngIf="groupingColumn.groupingId == inputModel.id" class="selected-grouping-column column-{{+groupingColumn.innerIndex}}">
                <ng-container *ngFor="let flowObject of inputSelectedFlowObjects">
                    <div *ngIf="flowObject.groupingColumnId == groupingColumn.id" #selectedFlowObjectRef
                         class="shadow-sm selected-flow-object flow-object-type {{FlowObjectTypeClassName.get(+flowObject.typeId)}}"
                         matTooltipPosition="right"
                         [class.dummy-0]="flowObject.typeId == FlowObjectType.Dummy && flowObject.innerIndex == 0"
                         [class.dummy-1]="flowObject.typeId == FlowObjectType.Dummy && flowObject.innerIndex == 1"
                         [matTooltip]="flowObject.name"
                         [matTooltipDisabled]="!isOverflown(selectedFlowObjectRef)"
                         [attr.data-id]="flowObject.id"
                         (click)="toggleEditArea(flowObject)"
                    >
                        <div class="flow-object-body-container" #flowObjectBodyContainerRef>
                            <span class="flow-object-body">
                                {{flowObject.name}}
                            </span>
                        </div>

                        <!--<i *ngIf="canShowEditButton(flowObject)"
                           class="button edit fas fa-edit"
                           matTooltip="Editar"
                           matTooltipPosition="left"
                           (click)="toggleEditArea(flowObject)"
                        ></i>-->

                        <i *ngIf="canShowVerticalMoveButton(flowObject) && canShowMoveUpwardsButton(flowObject)"
                           class="button center top fas fa-arrow-up"
                           matTooltip="Mover Para Sequência Anterior"
                           matTooltipPosition="above"
                           (click)="moveGroupingUpwards($event, flowObject)"
                        ></i>

                        <i *ngIf="canShowRemoveButton(flowObject)"
                           class="button remove fas fa-trash-alt"
                           matTooltip="Remover"
                           matTooltipPosition="right"
                           (click)="removeGrouping($event, flowObject)"
                        ></i>

                        <i *ngIf="canShowChangeButton(flowObject)"
                           class="button change fas fa-exchange-alt"
                           matTooltip="Trocar tipo da Tarefa dentre as possíveis para esta posição"
                           matTooltipPosition="right"
                           (click)="changeTaskType($event, flowObject)"
                        ></i>

                        <i *ngIf="canShowHorizontalMoveButton(flowObject) && canShowMoveBackwardsButton(flowObject)"
                           class="button left fas fa-arrow-left"
                           matTooltip="Mover Para Trás"
                           matTooltipPosition="left"
                           (click)="moveGroupingBackwards($event, flowObject)"
                        ></i>

                        <i *ngIf="canShowVerticalMoveButton(flowObject) && canShowMoveDownwardsButton(flowObject)"
                           class="button center bottom fas fa-arrow-down"
                           matTooltip="Mover Para Sequência Seguinte"
                           matTooltipPosition="below"
                           (click)="moveGroupingDownwards($event, flowObject)"
                        ></i>

                        <i *ngIf="canShowHorizontalMoveButton(flowObject) && canShowMoveForwardsButton(flowObject)"
                           class="button right fas fa-arrow-right"
                           matTooltip="Mover Para Frente"
                           matTooltipPosition="right"
                           (click)="moveGroupingForwards($event, flowObject)"
                        ></i>

                        <div class="task-icon-container" *ngIf="flowObject.typeId != FlowObjectType.Dummy">
                            <i *ngIf="[FlowObjectType.StartForm, FlowObjectType.FinishContent].includes(flowObject.typeId)" class="icon fas fa-file-alt"></i>
                            <i *ngIf="[FlowObjectType.StartInboundApi].includes(flowObject.typeId)" class="icon fas fa-external-link-square-alt"></i>
                            <i *ngIf="[FlowObjectType.TaskForward].includes(flowObject.typeId)" class="icon fas fa-paper-plane"></i>
                            <i *ngIf="[FlowObjectType.TaskAcknowledge].includes(flowObject.typeId)" class="icon fas fa-check"></i>
                            <i *ngIf="[FlowObjectType.TaskMidwayOutboundApi, FlowObjectType.FinishOutboundApi].includes(flowObject.typeId)" class="icon fas fa-external-link-alt"></i>
                            <i *ngIf="[FlowObjectType.TaskRegisterProcess].includes(flowObject.typeId)" class="icon fas fa-suitcase"></i>
                            <i *ngIf="[FlowObjectType.TaskDispatchProcess].includes(flowObject.typeId)" class="icon fas fa-forward"></i>
                            <i *ngIf="[FlowObjectType.GatewayApprove].includes(flowObject.typeId)" class="icon fas fa-thumbs-up"></i>
                            <i *ngIf="[FlowObjectType.GatewayRules].includes(flowObject.typeId)" class="icon fas fa-gavel"></i>
                            <i *ngIf="[FlowObjectType.GatewayPaths].includes(flowObject.typeId)" class="icon fas fa-random"></i>
                            <i *ngIf="[FlowObjectType.Finish].includes(flowObject.typeId)" class="icon fas fa-stop-circle"></i>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
</ng-container>
