"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  'border-default': '',
  'formio-tab-panel-active': 'active',
  'formio-tab-link-active': 'active',
  'formio-tab-link-container-active': 'active'
};
exports.default = _default;