import { FlowObjectType } from '../models/flow-object.model';

export const TaskRules = {
    getValidNextTaskTypes(currentType: FlowObjectType) {
        switch (currentType) {
            case FlowObjectType.StartInboundApi:
                return [
                    FlowObjectType.TaskForward,
                    FlowObjectType.TaskMidwayOutboundApi,
                    FlowObjectType.GatewayPaths
                ];

            case FlowObjectType.StartForm:
                return [
                    FlowObjectType.TaskForward,
                    FlowObjectType.TaskMidwayOutboundApi,
                    FlowObjectType.GatewayPaths
                ];

            case FlowObjectType.TaskAcknowledge:
                return [
                    FlowObjectType.TaskForward,
                    FlowObjectType.TaskRegisterProcess,
                    FlowObjectType.GatewayPaths,
                    FlowObjectType.Finish,
                    FlowObjectType.FinishContent,
                    FlowObjectType.FinishOutboundApi
                ];

            case FlowObjectType.TaskForward:
                return [
                    FlowObjectType.GatewayApprove,
                    FlowObjectType.TaskAcknowledge,
                    FlowObjectType.TaskRegisterProcess,
                    FlowObjectType.GatewayPaths,
                    FlowObjectType.Finish,
                    FlowObjectType.FinishContent,
                    FlowObjectType.FinishOutboundApi
                ];

            case FlowObjectType.TaskRegisterProcess:
                return [
                    FlowObjectType.TaskDispatchProcess,
                    FlowObjectType.GatewayPaths,
                    FlowObjectType.Finish,
                    FlowObjectType.FinishContent,
                    FlowObjectType.FinishOutboundApi
                ];

            case FlowObjectType.TaskDispatchProcess:
                return [
                    FlowObjectType.GatewayPaths,
                    FlowObjectType.Finish,
                    FlowObjectType.FinishContent,
                    FlowObjectType.FinishOutboundApi
                ];

            case FlowObjectType.TaskMidwayOutboundApi:
                return [
                    FlowObjectType.TaskForward,
                    FlowObjectType.GatewayPaths,
                    FlowObjectType.GatewayRules
                ];

            case FlowObjectType.GatewayApprove:
                return [
                    FlowObjectType.TaskForward,
                    FlowObjectType.TaskRegisterProcess,
                    FlowObjectType.GatewayPaths,
                    FlowObjectType.Finish,
                    FlowObjectType.FinishContent,
                    FlowObjectType.FinishOutboundApi
                ];

            case FlowObjectType.GatewayRules:
                return [
                    FlowObjectType.TaskForward,
                    FlowObjectType.GatewayPaths,
                    FlowObjectType.Finish,
                    FlowObjectType.FinishContent,
                    FlowObjectType.FinishOutboundApi
                ];

            case FlowObjectType.GatewayPaths:
                return [
                    FlowObjectType.Dummy
                ];

            case FlowObjectType.Finish:
            case FlowObjectType.FinishContent:
            case FlowObjectType.FinishOutboundApi:
            default:
                return [];
        }
    },

    getStarterTaskTypes() {
        return [
            FlowObjectType.StartForm,
            FlowObjectType.StartInboundApi
        ];
    },

    getFinisherTaskTypes() {
        return [
            FlowObjectType.Finish,
            FlowObjectType.FinishOutboundApi,
            FlowObjectType.FinishContent
        ];
    },

    getFormioTaskTypes() {
        return [
            FlowObjectType.StartForm,
            FlowObjectType.StartInboundApi
        ];
    },

    getStartInboundApiTaskTypes() {
        return [
            FlowObjectType.StartInboundApi
        ];
    },

    getForwardingTaskTypes() {
        return [
            FlowObjectType.TaskForward
        ];
    },

    getRegisterProcessTaskTypes() {
        return [
            FlowObjectType.TaskRegisterProcess
        ];
    },

    getDispatchProcessTaskTypes() {
        return [
            FlowObjectType.TaskDispatchProcess
        ];
    },

    getDocumentGeneratingTaskTypes() {
        return [
            FlowObjectType.StartForm,
            FlowObjectType.GatewayApprove,
            FlowObjectType.TaskAcknowledge,
            FlowObjectType.FinishContent
        ];
    },

    getMidwayOutboundApiTaskTypes() {
        return [
            FlowObjectType.TaskMidwayOutboundApi
        ];
    },

    getOutboundApiTaskTypes() {
        return [
            FlowObjectType.FinishOutboundApi
        ];
    },

    getGatewayPathsTaskTypes() {
        return [
            FlowObjectType.GatewayPaths
        ];
    },

    getConfigSchemaDependentOnMainTabTaskTypes() {
        return [
            FlowObjectType.GatewayRules,
            FlowObjectType.TaskMidwayOutboundApi,
            FlowObjectType.FinishOutboundApi,
            FlowObjectType.FinishContent,
            FlowObjectType.GatewayPaths
        ];
    }
};
