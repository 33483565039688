export namespace Enums {
    export enum Messages {
        AcessoCidadaoExpiredWarning = 'Sessão expirada no Acesso Cidadão. Aguarde a renovação da sessão na janela aberta automaticamente e, após seu fechamento, tente realizar aqui novamente a última ação solicitada',
        GeneralError = 'Erro ao executar a operação (FE01)',
        GeneralErrorInterceptor = 'Erro ao executar a operação (FE07)',
        CsvExportError = 'Erro ao gerar os arquivos CSV',
        CsvImportError = 'Erro ao importar dados do arquivo CSV',
        CsvEmptyError = 'Arquivo CSV vazio',
        CsvNoRecordsError = 'Nenhum registro no formato esperado foi encontrado no arquivo CSV fornecido',
        CsvImportSuccess = 'Dados do arquivo CSV importados com sucesso',
        NoFormSchemaError = 'Não foi possível carregar as definições do Construtor de Formulário',
        CannotCaptureAsCitizenError = 'Tarefas de preenchimento de formulário não podem conter, na definição do formulário, anexos configurados para captura como "Cidadão" caso o público-alvo do Fluxo não seja "Cidadão"',
        CannotCaptureAsPublicAgentError = 'Tarefas de preenchimento de formulário não podem conter, na definição do formulário, anexos configurados para captura como "Servidor" caso o público-alvo do Fluxo seja "Cidadão"',
        NoFormBuilderButtonError = 'Tarefas de preenchimento de formulário devem conter, na definição do formulário, um Botão para que o usuário possa submetê-lo',
        NoDocumentClassIdError = 'Tarefas que sejam executadas por servidores e que geram documento próprio capturado no E-Docs devem conter uma definição para a Classificação Documental',
        NoRecipientError = 'Tarefas de encaminhamento de conteúdo devem conter uma definição para o Destinatário do encaminhamento que será realizado no E-Docs',
        NoProcessLocation = 'Tarefas de autuação de Processo devem conter uma definição para o Local de Autuação do mesmo em sua configuração do E-Docs',
        NoProcessClass = 'Tarefas de autuação de Processo devem conter uma definição para a Classificação Processual em sua configuração do E-Docs',
        NoProcessSummary = 'Tarefas de autuação de Processo devem conter uma definição para o Resumo do Processo em sua configuração do E-Docs',
        NoProcessStakeholders = 'Tarefas de autuação de Processo devem conter ao menos um Interessado do Processo em sua configuração do E-Docs',
        NoDispatchRecipient = 'Tarefas de despacho de Processo devem conter um Destinatário do Despacho em sua configuração do E-Docs',
        NoDispatchMessage = 'Tarefas de despacho de Processo devem conter uma Mensagem de Despacho em sua configuração do E-Docs',
        NoDispatchAccessLevel = 'Tarefas de despacho de Processo devem conter o Nível de Acesso do Termo de Despacho em sua configuração do E-Docs',
        NoResolverIdError = 'Tarefas de API de saída devem conter uma definição para seus Atores Resolvedores',
        NoEndpointUrlError = 'Tarefas de API de saída devem conter uma definição para a URL do endpoint de destino',
        NoConfigSchemaError = 'Configuração de Tarefa não encontrada',
        NoFlowInboxError = 'O usuário não possui acesso de Administrador em nenhuma Caixa de Fluxo',
        NoGatewayPathsSequenceNameError = 'Tarefas de Divisão de Fluxo devem conter, em suas configurações, nomes para suas sequências de Tarefas',
        NoGatewayPathsConditionsError = 'Tarefas de Divisão de Fluxo devem conter, em suas configurações, ao menos uma condição para divisão entre suas sequências de Tarefas',
        NoGatewayPathsFormFieldError = 'Toda regra de condição de uma Tarefa de Divisão de Fluxo deve conter um código de campo do formulário utilizado para comparação',
        EmptyGatewayPathError = 'As Sequências da Divisão de Fluxo devem conter ao menos uma Tarefa cada',
        SingleGatewayPathsTaskOnly = 'Todo Fluxo deve possuir no máximo uma Tarefa de Divisão de Fluxo',
        TargetCitizenImmediateSupervisorError = 'Fluxos com público-alvo "Cidadão" não podem apresentar Tarefas de encaminhamento de conteúdo com o Destinatário sendo "Gestor Imediato"',
        ConditionalRecipientsOnStartInboundApiError = 'Fluxos que se iniciem por API não podem conter destinatários condicionais em Tarefas de encaminhamento ou despacho',
        AccessDenied = 'Acesso Negado',
        NoAccessToResource = 'Você não possui acesso a esse recurso',
        MyNewFlow = 'Meu Novo Fluxo',
        Warning = 'Aviso',
        Error = 'Erro',
        Success = 'Sucesso',
        EnvironmentLocalhost = 'Localhost',
        EnvironmentDevelopment = 'Desenvolvimento',
        EnvironmentStaging = 'Homologação',
        EnvironmentLearning = 'Treinamento',
        SketchSaveSuccess = 'Alterações em modo rascunho salvas com sucesso',
        DataValidationError = 'Erro na validação de dados',
        FlowStarterTaskNotFound = 'Tarefa Disparadora de Fluxo não encontrada',
        FirstTaskShouldBeStart = 'A primeira tarefa do Fluxo deve ser de um dos tipos de "Início"',
        LastTaskShoudBeFinish = 'Todas as possíveis últimas tarefas do Fluxo devem ser do tipo "Fim"',
        FlowDefinitionIsReadOnly = 'O Fluxo não pode ser alterado em modo "Somente Leitura"',
        InvalidNextType = 'Encadeamento de Tarefas não permitido. Tarefas do tipo "{0}" só podem ser sucedidas por Tarefas dos seguintes tipos: {1}',
        PdfFilesOnly = 'Apenas arquivos com extensão PDF são permitidos',
        PdfMaxSizeLimit = 'O tamanho do arquivo PDF deve ser menor que {0}MB',
        FileMaxSizeLimit = 'O tamanho do arquivo deve ser menor que {0}MB',
        InvalidFileExtension = 'Apenas arquivos com as seguintes extensões são permitidos: "{0}"',
        JsonDeserializeError = 'Erro ao desserializar JSON: um ou mais pares de chave/valor possuem valores não suportados',
        JsonSerializeError = 'Erro ao serializar JSON: um ou mais pares de chave/valor possuem valores não suportados',
        KeyAlreadyAdded = 'Chave já adicionada anteriormente',
        EmptyKey = 'Chave não informada',
        EmptyPairValueType = 'Tipo de Valor do par de chave/valor não informado',
        NullOrEmpty = 'Valor informado nulo ou vazio',
        IsNotNumber = 'Regra selecionada não aplicável para valores de tipo texto',
        ConditionalFieldAdvanced = 'Este campo possui exibição condicional (avançado)',
        ConditionalFieldSimple = 'Este campo possui exibição condicional (simples)',
        InvalidKeyTemplate = 'A chave não está no formato do template "{{chave}}"',
        InvalidPairValue = 'Valor inválido para o Tipo de Valor escolhido',
        InvalidAutoDeactivateOptions = 'Valor inválido para as opções de desativação automática',
        InvalidAutoCancelOptions = 'Valor inválido para as opções de cancelamento automático',
        InvalidFlowObjectConfigSchemaSource = 'Valor inválido de código-fonte para as Configurações da Tarefa',
        InvalidFlowDefinitionConfigSchemaSource = 'Valor inválido de código-fonte para as Configurações do Fluxo',
        InvalidConfigSchemaSourceType = 'O tipo das Configurações de origem não coincide com o tipo das Configurações de destino',
        NameMinLength = 'O campo "Nome" deve apresentar um tamanho mínimo de 3 caracteres (não vazios)',
        NoFlowInboxId = 'Adicione um critério para uma Caixa de Fluxos a fim de trazer novos resultados',
        NoOptionAvailable = '(nenhuma opção disponível)',
        SelectAnOption = '(selecione uma opção)',
        MandatoryConditionalFields = 'Entre com um valor para "Campo do Formulário" e "Valor do Campo"',
        MandatoryProcessSummary = 'Entre com um resumo para o Processo (ao menos 5 caracteres)',
        MandatoryDispatchSummary = 'Entre com uma mensagem para o Despacho (ao menos 5 caracteres)',
        FormSchemaParsingError = 'Configuração de formulário inválida. As alterações não foram aplicadas',
        MandatoryWaitingMessage = 'É obrigatório o fornecimento de mensagem de espera ao requerente. Tamanho mínimo: 10 caracteres',
        MandatoryAttachment = 'É necessária a configuração de ao menos um Anexo',
        MandatoryAttachmentDocumentClass = 'Todos os Anexos devem conter uma Classificação Documental',
        NewVersionConfirmMessage = `Deseja <b>gerar uma nova versão</b> para o Fluxo <b>{0}</b>?<br><br>
                                    Uma vez versionado, o Fluxo poderá ser editado novamente
                                    e só poderá instanciado por usuários na nova versão quando for publicado.`,
        CloneConfirmMessage = `Deseja <b>clonar</b> o Fluxo <b>{0}</b>?<br><br>
                               O Fluxo de origem não será alterado e suas eventuais instâncias
                               não possuirão relação com o Fluxo duplicado. Apenas a última
                               versão do Fluxo de origem será clonada.`,
        PublishConfirmMessage = `Deseja <b>publicar</b> o Fluxo <b>{0}</b>?<br><br>
                                 Uma vez publicado, o Fluxo estará disponível para instanciação por usuários
                                 e não poderá ser alterado até que uma nova versão seja criada.`,
        GroupByContextConfirmMessage = `Deseja <b>ativar o modo de Agrupamento de Fluxos por Contexto</b> para o Fluxo <b>{0}</b>?<br><br>
                                        Fluxos agrupados em um mesmo Contexto são exibidos em uma lista unificada para a escolha do usuário
                                        no momento da instanciação.`,
        ActivateConfirmMessage = `Deseja <b>ativar</b> o Fluxo <b>{0}</b>?<br><br>
                                  Uma vez ativado, o Fluxo estará disponível para novas
                                  instanciações por usuários, caso haja versões publicadas.`,
        DeactivateConfirmMessage = `Deseja <b>desativar</b> o Fluxo <b>{0}</b>?<br><br>
                                    Uma vez inativo, o Fluxo estará indisponível para novas
                                    instanciações por usuários, ainda que haja versões publicadas.`,
        DeleteConfirmMessage = `Deseja <b>remover</b> o Fluxo <b>{0}</b>?<br><br>
                                Uma vez removido, o Fluxo não poderá ser acessado novamente.`,
        PinConfirmMessage = `Deseja <b>fixar</b> o Fluxo <b>{0}</b> no início da lista?`,
        UnpinConfirmMessage = `Deseja <b>desafixar</b> o Fluxo <b>{0}</b> do início da lista?`,
        ImmediateChangeOnPublicWarning = `Deseja <b>confirmar e salvar</b> as alterações no Fluxo?<br><br>
                                          Opções referentes ao <b>cancelamento e/ou desativação automáticos</b> foram alteradas, o que reflete <b>imediatamente</b>
                                          nos Fluxos publicados e/ou em andamento no portal Público (isto é, <b>sem que seja necessário</b> publicar este Fluxo novamente).`
    }

    export enum Profiles {
        Administrator = 'Administrador',
        Owner = 'Owner',
        Public = 'Publico',
        ErrorPage = 'ErrorPage'
    }

    export enum GatewayPathsLabels {
        True = 'Sim',
        False = 'Não'
    }

    export enum PagesPaths {
        FlowDefinitionList = '/flow-definition-list',
        FlowDefinition = '/flow-definition',
        Dashboard = '/dashboard',
        UpdateHistory = '/update-history',
        Home = '/home',
        Error = '/error',
        AccessDenied = '/access-denied',
        LandingPage = '/'
    }

    export enum TitleInfoType {
        UserName = 0,
        UserCpf = 1,
        FormField = 2
    }

    export enum FlowTarget {
        Unit = 0,
        Organization = 1,
        Patriarch = 2,
        Citizen = 3
    }

    export const FlowTargetLabel = new Map<number, string>([
        [FlowTarget.Unit, 'Setor'],
        [FlowTarget.Organization, 'Órgão'],
        [FlowTarget.Patriarch, 'Patriarca'],
        [FlowTarget.Citizen, 'Cidadão']
    ]);

    export const FlowTargetDescription = new Map<number, string>([
        [FlowTarget.Unit, 'Apenas servidores com algum papel lotado no Setor relacionado à Caixa de Fluxos onde este Fluxo se encontra'],
        [FlowTarget.Organization, 'Apenas servidores com algum papel lotado no Órgão relacionado à Caixa de Fluxos onde este Fluxo se encontra'],
        [FlowTarget.Patriarch, 'Apenas servidores com algum papel lotado no Patriarca relacionado à Caixa de Fluxos onde este Fluxo se encontra'],
        [FlowTarget.Citizen, 'Qualquer cidadão']
    ]);

    export enum FlowDefinitionItemActionType {
        Open = 0,
        Instantiate = 1,
        NewVersion = 2,
        Clone = 3,
        Publish = 4,
        Activate = 5,
        Deactivate = 6,
        Delete = 7,
        GroupByContext = 8,
        AddItemToContext = 9,
        RemoveItemFromContext = 10,
        MoveUpInContext = 11,
        MoveDownInContext = 12,
        Pin = 13,
        Unpin = 14
    }

    export enum DetailsAreaTabs {
        FlowDefinitionDetails = 0,
        FlowDefinitionDetailsHotConfigs = 1,
        FlowObjectDetails = 2,
        FormBuilder = 3,
        FormBuilderInboundApi = 4,
        HtmlEditor = 5,
        GatewayRules = 6,
        GatewayPaths = 7,
        MidwayOutboundApi = 8,
        OutboundApi = 9,
        FinishContent = 10
    }

    export const DetailsAreaTabsLabel = new Map<DetailsAreaTabs, string>([
        [DetailsAreaTabs.FlowDefinitionDetails, 'Detalhes do Fluxo'],
        [DetailsAreaTabs.FlowDefinitionDetailsHotConfigs, 'Configurações Imediatas'],
        [DetailsAreaTabs.FlowObjectDetails, 'Detalhes'],
        [DetailsAreaTabs.FormBuilder, 'Construtor de Formulário'],
        [DetailsAreaTabs.FormBuilderInboundApi, 'Construtor de Formulário: Iniciar por API'],
        [DetailsAreaTabs.HtmlEditor, 'Editor de Modelo de Documento'],
        [DetailsAreaTabs.GatewayRules, 'Aplicação de Regra'],
        [DetailsAreaTabs.GatewayPaths, 'Divisão de Fluxo'],
        [DetailsAreaTabs.MidwayOutboundApi, 'API de Destino (Intermediária)'],
        [DetailsAreaTabs.OutboundApi, 'API de Destino (Final)'],
        [DetailsAreaTabs.FinishContent, 'Conteúdo de Encerramento']
    ]);

    export const DetailsAreaTabsLabelReversed = new Map<string, DetailsAreaTabs>([
        ['Detalhes do Fluxo', DetailsAreaTabs.FlowDefinitionDetails],
        ['Configurações Imediatas', DetailsAreaTabs.FlowDefinitionDetailsHotConfigs],
        ['Detalhes', DetailsAreaTabs.FlowObjectDetails],
        ['Construtor de Formulário', DetailsAreaTabs.FormBuilder],
        ['Construtor de Formulário: Iniciar por API', DetailsAreaTabs.FormBuilderInboundApi],
        ['Editor de Modelo de Documento', DetailsAreaTabs.HtmlEditor],
        ['Aplicação de Regra', DetailsAreaTabs.GatewayRules],
        ['Divisão de Fluxo', DetailsAreaTabs.GatewayPaths],
        ['API de Destino (Intermediária)', DetailsAreaTabs.MidwayOutboundApi],
        ['API de Destino (Final)', DetailsAreaTabs.OutboundApi],
        ['Conteúdo de Encerramento', DetailsAreaTabs.FinishContent]
    ]);

    export enum RecipientMode {
        ImmediateSupervisor = 1,
        Fixed = 2,
        Conditional = 3,
        PublicAgent = 4
    }

    export enum AccessLevelFilterType {
        Citizen = 1,
        Patriarch = 2
    }
}
