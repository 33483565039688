<ng-container *ngIf="model != null">
    <form class="px-1" (ngSubmit)="onSubmit()" #flowObjectFinishContent="ngForm">
        <div class="scroll-wrapper styled-scrollbar px-2">
            <!-- Mesagem de espera para o requerente -->
            <div class="form-group waiting-message-container ml-0 mb-4" [class.mandatory]="forgotWaitingMessage && waitingMessageLength < 10">
                <span>Entre com a mensagem que será exibida ao requerente enquanto aguarda pela execução da Tarefa:</span>

                <!-- Mensagem de espera -->
                <div class="form-group mt-2">
                    <editor name="waitingMessage" [init]="inputTinyMceOptionsWaitingMessage" [(ngModel)]="waitingMessage" *ngIf="inputShouldLoadTinyMce"></editor>
                    <textarea id="tinyMceFinishContentWaitingMessage" class="d-none"></textarea>
                </div>
            </div>

            <!-- Tipo de conteúdo -->
            <div class="form-group content-type-container ml-0 mb-3">
                <span>Selecione o tipo de conteúdo que será fornecido pelo servidor responsável no momento da execução da Tarefa:</span>

                <mat-radio-group class="my-2" id="contentType" name="contentType" color="primary" [(ngModel)]="contentType">
                    <!-- Texto -->
                    <ng-container>
                        <mat-radio-button [value]="FinishContentType.Text">Texto</mat-radio-button>

                        <!-- Info: Texto -->
                        <blockquote class="info-container radiobutton">
                            <div class="description info-highlight">
                                <i class="fas fa-info-circle info"></i>
                                <span>
                                    Um documento próprio, baseado em um template do E-Flow, será capturado a partir do conteúdo
                                    textual opcionalmente pré-configurado no campo abaixo (embora este ainda seja passível de edição
                                    pelo servidor responsável no momento da execução da Tarefa) e incluído no rastreio de Encaminhamentos
                                    que representa a instância de Fluxo sendo executada.
                                </span>
                            </div>
                        </blockquote>

                        <!-- Configuração do documento capturado a partir da mensagem fornecida -->
                        <div class="text-content-type-container ml-4 mt-3" *ngIf="contentType == FinishContentType.Text">
                            <!-- Mensagem pré-configurada -->
                            <div class="form-group content-type-text-row px-3 mt-3">
                                <label for="message">Mensagem pré-configurada</label>
                                <editor name="message" [init]="inputTinyMceOptionsMessage" [(ngModel)]="message" *ngIf="inputShouldLoadTinyMce"></editor>
                                <textarea id="tinyMceFinishContentMessage" class="d-none"></textarea>
                            </div>

                            <!-- Classificação Documental -->
                            <div class="content-type-text-row px-3">
                                <div class="form-group">
                                    <h5>
                                        <span>Classificação Documental</span>
                                    </h5>
                                </div>

                                <document-class [inputModel]="documentClass"
                                                [inputFlowDefinitionOrganizationId]="inputFlowDefinition.organizationId"
                                                [inputIsReadOnlyMode]="inputIsReadOnlyMode"
                                                (outputModelChange)="onUpdateDocumentClass($event)"
                                ></document-class>
                            </div>

                            <!-- Nível de Acesso -->
                            <div class="content-type-text-row px-3">
                                <div class="form-group">
                                    <h5>
                                        <span>Nível de Acesso do Documento</span>
                                    </h5>
                                </div>

                                <access-level [inputModel]="accessLevel"
                                              [inputFlowDefinitionOrganizationId]="inputFlowDefinition.organizationId"
                                              [inputIsReadOnlyMode]="inputIsReadOnlyMode"
                                              (outputModelChange)="onUpdateAccessLevel($event)"
                                ></access-level>
                            </div>
                        </div>
                    </ng-container>

                    <!-- Anexos -->
                    <ng-container>
                        <mat-radio-button class="mt-3" [value]="FinishContentType.Attachment">Anexos</mat-radio-button>

                        <!-- Info: Anexos -->
                        <blockquote class="info-container radiobutton">
                            <div class="description info-highlight">
                                <i class="fas fa-info-circle info"></i>
                                <span>
                                    Um ou mais documentos PDF serão fornecidos pelo servidor responsável no momento da execução da Tarefa
                                    e incluídos no rastreio de Encaminhamentos que representa a instância de Fluxo sendo executada.
                                </span>
                            </div>
                        </blockquote>

                        <!-- Configuração dos anexos -->
                        <div class="attachment-content-type-container mb-2" *ngIf="contentType == FinishContentType.Attachment">
                            <div class="attachment-container p-3 mt-3" *ngFor="let item of attachments; let i = index">
                                <!-- Remover Anexo -->
                                <button type="button" class="btn btn-outline-danger remove" matTooltip="Remover este anexo" (click)="removeAttachment(item)">
                                    <i class="fas fa-trash mr-2"></i>
                                    <span>Remover</span>
                                </button>

                                <!-- Nome do Anexo -->
                                <ng-container>
                                    <div class="form-group">
                                        <label for="label_{{i}}">Nome do Anexo</label>
                                        <input type="text" class="form-control" id="label_{{i}}" name="label_{{i}}" #labelElem="ngModel" maxlength="100" required
                                               [(ngModel)]="item.label"
                                        >
                                    </div>
                                    <div [hidden]="labelElem.valid || labelElem.pristine" class="alert alert-danger">Campo obrigatório</div>

                                    <blockquote class="info-container field attachment-label mb-3" *ngIf="!Utils.isNullOrEmpty(item.label)">
                                        <div class="description info-highlight">
                                            <i class="fas fa-info-circle info"></i>
                                            <span>
                                                Com as configurações atuais, o nome final deste anexo quando capturado no E-Docs será:
                                                <br />
                                                <span class="code-font text-highlight">
                                                    {{getFinalDocumentNamePreview(item.label)}}
                                                </span>
                                            </span>
                                        </div>
                                    </blockquote>
                                </ng-container>

                                <!-- Valor Legal do Documento para Conferência -->
                                <ng-container>
                                    <div class="form-group">
                                        <label for="dlvc_{{i}}">Valor Legal do Documento para Conferência</label>
                                        <i class="fas fa-question-circle ml-1"
                                           matTooltip="Valor Legal do documento anexado que será capturado no E-Docs"
                                        ></i>
                                        <select class="form-control px-1" id="dlvc_{{i}}" name="dlvc_{{i}}" #dlvcElem="ngModel" required
                                                [(ngModel)]="item.documentLegalValueCombination"
                                        >
                                            <option *ngFor="let option of documentLegalValueCombinationOptions" [value]="option.value">{{option.description}}</option>
                                        </select>
                                        <div [hidden]="dlvcElem.valid || dlvcElem.pristine" class="alert alert-danger">Campo obrigatório</div>
                                    </div>
                                </ng-container>

                                <!-- Classificação Documental -->
                                <ng-container>
                                    <div class="form-group mt-4">
                                        <h5>
                                            <span>Classificação Documental</span>
                                        </h5>
                                    </div>

                                    <document-class [inputModel]="item.documentClass"
                                                    [inputFlowDefinitionOrganizationId]="inputFlowDefinition.organizationId"
                                                    [inputIsReadOnlyMode]="inputIsReadOnlyMode"
                                                    (outputModelChange)="onUpdateDocumentClass($event, item)"
                                    ></document-class>
                                </ng-container>

                                <!-- Nível de Acesso -->
                                <ng-container>
                                    <div class="form-group mt-4">
                                        <h5>
                                            <span>Nível de Acesso do Documento</span>
                                        </h5>
                                    </div>

                                    <access-level #accessLevelRef
                                                  [inputModel]="item.accessLevel"
                                                  [inputFlowDefinitionOrganizationId]="inputFlowDefinition.organizationId"
                                                  [inputIsReadOnlyMode]="inputIsReadOnlyMode"
                                                  (outputModelChange)="onUpdateAccessLevel($event, item)"
                                    ></access-level>
                                </ng-container>
                            </div>

                            <!-- Adicionar Anexo -->
                            <div class="d-flex justify-content-center mt-3" *ngIf="canAddAttachment">
                                <button type="button" class="btn btn-outline-success add" (click)="addAttachment()">
                                    <i class="fas fa-plus mr-2"></i>
                                    <span>Adicionar Anexo</span>
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </mat-radio-group>
            </div>
        </div>

        <!-- Botões -->
        <div class="form-footer text-right mb-1">
            <hr class="mt-0" />
            <button type="button" class="btn btn-outline-danger mr-2" (click)="closeForm()">Cancelar</button>
            <button type="submit" class="btn btn-outline-primary"
                    [disabled]="!flowObjectFinishContent.form.valid || inputIsReadOnlyMode"
            >Salvar Configurações</button>
        </div>
    </form>
</ng-container>
