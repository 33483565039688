import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { FlowObjectDefinition } from "../../../../models/flow-object.model";
import { FlowDefinition } from "../../../../models/flow.model";
import { ToastrService } from "ngx-toastr";
import { Enums } from "src/app/shared/enums";
import { Utils } from "../../../../shared/utils";
import { IBaseOption } from "../../../../models/base.model";
import {
    ConfigSchema,
    ConfigSchemaSectionAccessLevel,
    ConfigSchemaSectionDocumentClass,
    FinishContentAttachment,
    FinishContentType
} from "../../../../models/config-schema.model";
import { DocumentLegalValueCombination, DocumentLegalValueCombinationDescription } from "../../../../models/edocs.model";

@Component({
    selector: "flow-object-details-finish-content",
    templateUrl: "./flow-object-details-finish-content.component.html",
    styleUrls: ["./flow-object-details-finish-content.component.scss"]
})

export class FlowObjectDetailsFinishContentComponent implements OnInit {
    // #region [Type properties]
    FinishContentType: typeof FinishContentType = FinishContentType;
    Utils: typeof Utils = Utils;
    // #endregion

    // #region [const]
    MAX_ATTACHMENTS: number = 3 as const;
    // #endregion

    // #region [properties]
    model: FlowObjectDefinition;
    configSchema = new ConfigSchema();
    contentType: FinishContentType = null;
    documentClass: ConfigSchemaSectionDocumentClass = null;
    accessLevel: ConfigSchemaSectionAccessLevel = null;
    attachments: FinishContentAttachment[] = [];
    documentLegalValueCombinationOptions: IBaseOption[] = [];
    message: string = '';
    waitingMessage: string = '';
    forgotWaitingMessage: boolean = false;
    // #endregion

    // #region [getters]
    get canAddAttachment(): boolean {
        return this.attachments.length < 3;
    }
    get waitingMessageLength(): number {
        let div = document.createElement('div');
        div.innerHTML = this.waitingMessage?.replaceAll('<br />', '\n');

        return (div.textContent || div.innerText || '').length;
    }
    // #endregion

    // #region [Input/Output]
    @Input() inputModel: FlowObjectDefinition;
    @Output() inputModelChange = new EventEmitter<FlowObjectDefinition>();
    @Input() inputFlowDefinition: FlowDefinition;
    @Input() inputIsReadOnlyMode: boolean;
    @Input() inputShouldLoadTinyMce: boolean;
    @Input() inputTinyMceOptionsMessage: string;
    @Input() inputTinyMceOptionsWaitingMessage: string;
    @Output() outputSubmitEvent = new EventEmitter<FlowObjectDefinition>();
    @Output() outputCloseEvent = new EventEmitter<any>();
    // #endregion

    constructor(
        private toastr: ToastrService
    ) {
        for (let item in DocumentLegalValueCombination) {
            if (!isNaN(parseInt(item)) && parseInt(item) < 7) {
                this.documentLegalValueCombinationOptions.push({
                    value: +item,
                    description: DocumentLegalValueCombinationDescription.get(+item)
                });
            }
        }
    }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() {
        setTimeout(async () => {
            this.model = this.inputModel;

            if (this.model?.configSchema != null) {
                this.configSchema = JSON.parse(this.model.configSchema) as ConfigSchema;

                this.contentType = this.configSchema.taskFinishContent.contentType || FinishContentType.Text;
                this.message = this.configSchema.taskFinishContent.message?.replaceAll('\n', '<br />');
                this.waitingMessage = this.configSchema.taskFinishContent.waitingMessage?.replaceAll('\n', '<br />');
                this.documentClass = this.configSchema.taskFinishContent.documentClass || new ConfigSchemaSectionDocumentClass();
                this.accessLevel = this.configSchema.taskFinishContent.accessLevel || new ConfigSchemaSectionAccessLevel();
                this.attachments = this.configSchema.taskFinishContent.attachments?.length > 0
                    ? this.configSchema.taskFinishContent.attachments.sort((a, b) => a.timestamp.localeCompare(b.timestamp))
                    : [new FinishContentAttachment()];
            }
        }, 1);
    }

    // ======================
    // public methods
    // ======================

    addAttachment() {
        if (this.inputIsReadOnlyMode) return;

        if (this.attachments.length < this.MAX_ATTACHMENTS) {
            this.attachments.push(new FinishContentAttachment());
        }
    }

    removeAttachment(item: FinishContentAttachment) {
        if (this.inputIsReadOnlyMode) return;

        this.attachments = this.attachments.filter(x => x.id != item.id);
    }

    getFinalDocumentNamePreview(label: string): string {
        return 'E-Flow | {0}{1} - {2} | {3} | {4} | {5}'
            .replace('{0}', this.inputFlowDefinition.patriarchInfo.split('-')[0].trim())
            .replace('{1}', this.inputFlowDefinition.patriarchInfo.split('-')[0].trim() != this.inputFlowDefinition.organizationInfo.split('-')[0].trim()
                ? ' - ' + this.inputFlowDefinition.organizationInfo.split('-')[0].trim()
                : ''
            )
            .replace('{2}', this.inputFlowDefinition.unitInfo.split('-')[0].trim())
            .replace('{3}', this.inputFlowDefinition.name.trim())
            .replace('{4}', this.model.name.trim())
            .replace('{5}', label.trim());
    }

    onUpdateDocumentClass(event: ConfigSchemaSectionDocumentClass, item?: FinishContentAttachment) {
        if (item != null) {
            item.documentClass = event;
        } else {
            this.documentClass = event;
        }
    }

    onUpdateAccessLevel(event: ConfigSchemaSectionAccessLevel, item?: FinishContentAttachment) {
        if (item != null) {
            item.accessLevel = event;
        } else {
            this.accessLevel = event;
        }
    }

    onSubmit() {
        if (this.inputIsReadOnlyMode) return false;

        if (Utils.isNullOrEmpty(this.waitingMessage) || this.waitingMessage.length < 10) {
            this.toastr.warning(Enums.Messages.MandatoryWaitingMessage, Enums.Messages.Warning, Utils.getToastrErrorOptions());
            this.forgotWaitingMessage = true;
            return false;
        } else {
            this.forgotWaitingMessage = false;
        }

        if (this.contentType == FinishContentType.Attachment) {
            if (this.attachments.length == 0) {
                this.toastr.warning(Enums.Messages.MandatoryAttachment, Enums.Messages.Warning, Utils.getToastrErrorOptions());
                return false;
            }

            if (this.attachments.some(x => Utils.isNullOrEmpty(x.documentClass.id))) {
                this.toastr.warning(Enums.Messages.MandatoryAttachmentDocumentClass, Enums.Messages.Warning, Utils.getToastrErrorOptions());
                return false;
            }

            this.message = '';
        } else {
            this.attachments = [];
        }

        this.configSchema.taskFinishContent.contentType = this.contentType;
        this.configSchema.taskFinishContent.documentClass = this.documentClass;
        this.configSchema.taskFinishContent.accessLevel = this.accessLevel;
        this.configSchema.taskFinishContent.attachments = this.attachments;

        let div = document.createElement('div');
        div.innerHTML = this.message?.replaceAll('<br />', '\n');
        this.configSchema.taskFinishContent.message = div.textContent || div.innerText || '';

        div = document.createElement('div');
        div.innerHTML = this.waitingMessage?.replaceAll('<br />', '\n');
        this.configSchema.taskFinishContent.waitingMessage = div.textContent || div.innerText || '';

        this.model.configSchema = JSON.stringify(this.configSchema);
        this.outputSubmitEvent.emit(this.model);
    }

    closeForm() {
        this.outputCloseEvent.emit();
    }

    // ======================
    // private methods
    // ======================
}
