import { Const } from "../../../shared/const";

export const FLOW_OBJECT_DETAILS_TINYMCE_OPTIONS = {
    selector: '#tinyMce',
    language: 'pt_BR',
    height: '220px',
    content_css: '/assets/css/flow-object-details-tinymce.css',
    body_class: 'tinymce-body',
    theme: 'silver',
    //forced_root_block: false,
    statusbar: false,
    menubar: false,
    contextmenu: false,
    branding: false,
    fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt',

    // evita que o tinyMce altere as URLs das imagens fornecidas
    relative_urls: false,
    remove_script_host: false,
    convert_urls: false,
    default_link_target: '_blank',

    plugins: [
        //'preview',
        //'fullscreen',
        'searchreplace code link'
    ],

    toolbar1: 'styleselect fontsizeselect | searchreplace | charmap subscript superscript | removeformat | code | link',
    toolbar2: 'bold italic underline | forecolor backcolor | bullist numlist | undo redo | visualchars visualblocks | vEDocs',
    //toolbar1: 'fontsizeselect | searchreplace | code fullscreen',
    //toolbar1: 'styleselect fontsizeselect | outdent indent | searchreplace | charmap subscript superscript | removeformat',
    //toolbar2: 'bold italic underline strikethrough | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | table | undo redo | visualchars visualblocks | code fullscreen',
    //toolbar3: 'pdfPreview | vPessoas vLocais vDatas vForm',

    table_toolbar: '',

    mobile: {
        theme: 'mobile',
        plugins: ['autosave', 'lists', 'autolink'],
        toolbar: ['undo', 'bold', 'italic', 'styleselect'],
        height: '50px'
    },

    setup: editor => {
        editor.ui.registry.addMenuButton('vEDocs', {
            text: 'Adicionar Protocolo E-Docs',
            fetch: callback => callback([
                {
                    type: 'nestedmenuitem',
                    text: 'Encaminhamento',
                    getSubmenuItems: () => [
                        {
                            type: 'menuitem',
                            text: 'Protocolo',
                            onAction: () => editor.insertContent(Const.TagPublicMessageForwardingProtocol)
                        },
                        {
                            type: 'menuitem',
                            text: 'Link com protocolo para o E-Docs',
                            onAction: () => editor.insertContent(Const.TagPublicMessageForwardingLink)
                        }
                    ]
                },
                {
                    type: 'nestedmenuitem',
                    text: 'Processo',
                    getSubmenuItems: () => [
                        {
                            type: 'menuitem',
                            text: 'Protocolo',
                            onAction: () => editor.insertContent(Const.TagPublicMessageProcessProtocol)
                        },
                        {
                            type: 'menuitem',
                            text: 'Link com protocolo para o E-Docs',
                            onAction: () => editor.insertContent(Const.TagPublicMessageProcessLink)
                        }
                    ]
                }
            ])
        });
    }
};

export const FLOW_OBJECT_DETAILS_START_INBOUND_API_TINYMCE_OPTIONS = {
    selector: '#tinyMce',
    language: 'pt_BR',
    height: '220px',
    content_css: '/assets/css/flow-object-details-tinymce.css',
    body_class: 'tinymce-body',
    theme: 'silver',
    //forced_root_block: false,
    statusbar: false,
    menubar: false,
    contextmenu: false,
    branding: false,
    fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt',

    // evita que o tinyMce altere as URLs das imagens fornecidas
    relative_urls: false,
    remove_script_host: false,
    convert_urls: false,
    default_link_target: '_blank',

    plugins: [
        //'preview',
        //'fullscreen',
        'searchreplace code link'
    ],

    toolbar1: 'styleselect fontsizeselect | searchreplace | charmap subscript superscript | removeformat | code | link',
    toolbar2: 'bold italic underline | forecolor backcolor | bullist numlist | undo redo | visualchars visualblocks | vEDocs',
    //toolbar1: 'fontsizeselect | searchreplace | code fullscreen',
    //toolbar1: 'styleselect fontsizeselect | outdent indent | searchreplace | charmap subscript superscript | removeformat',
    //toolbar2: 'bold italic underline strikethrough | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | table | undo redo | visualchars visualblocks | code fullscreen',
    //toolbar3: 'pdfPreview | vPessoas vLocais vDatas vForm',

    table_toolbar: '',

    mobile: {
        theme: 'mobile',
        plugins: ['autosave', 'lists', 'autolink'],
        toolbar: ['undo', 'bold', 'italic', 'styleselect'],
        height: '50px'
    }
};

export const FLOW_OBJECT_DETAILS_REGISTER_PROCESS_TINYMCE_OPTIONS = {
    selector: '#tinyMceRegisterProcess',
    language: 'pt_BR',
    height: '220px',
    content_css: '/assets/css/flow-object-details-tinymce.css',
    body_class: 'tinymce-body',
    theme: 'silver',
    forced_root_block: false,
    statusbar: false,
    menubar: false,
    contextmenu: false,
    branding: false,
    fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt',

    // evita que o tinyMce altere as URLs das imagens fornecidas
    relative_urls: false,
    remove_script_host: false,
    convert_urls: false,
    default_link_target: '_blank',

    plugins: [
        'searchreplace'
    ],

    toolbar1: 'searchreplace | undo redo | vForm',
    //toolbar1: 'fontsizeselect | searchreplace | code fullscreen',
    //toolbar1: 'styleselect fontsizeselect | outdent indent | searchreplace | charmap subscript superscript | removeformat',
    //toolbar2: 'bold italic underline strikethrough | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | table | undo redo | visualchars visualblocks | code fullscreen',
    //toolbar3: 'pdfPreview | vPessoas vLocais vDatas vForm',

    table_toolbar: '',

    mobile: {
        theme: 'mobile',
        plugins: ['autosave', 'lists', 'autolink'],
        toolbar: ['undo', 'bold', 'italic', 'styleselect'],
        height: '50px'
    },

    addDynamicMenu: editor => { },

    setup: editor => {
        FLOW_OBJECT_DETAILS_REGISTER_PROCESS_TINYMCE_OPTIONS.addDynamicMenu(editor);
    }
};

export const FLOW_OBJECT_DETAILS_DISPATCH_PROCESS_TINYMCE_OPTIONS = {
    selector: '#tinyMceDispatchProcess',
    language: 'pt_BR',
    height: '220px',
    content_css: '/assets/css/flow-object-details-tinymce.css',
    body_class: 'tinymce-body',
    theme: 'silver',
    forced_root_block: false,
    statusbar: false,
    menubar: false,
    contextmenu: false,
    branding: false,
    fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt',

    // evita que o tinyMce altere as URLs das imagens fornecidas
    relative_urls: false,
    remove_script_host: false,
    convert_urls: false,
    default_link_target: '_blank',

    plugins: [
        'searchreplace'
    ],

    toolbar1: 'searchreplace | undo redo | vForm',
    //toolbar1: 'fontsizeselect | searchreplace | code fullscreen',
    //toolbar1: 'styleselect fontsizeselect | outdent indent | searchreplace | charmap subscript superscript | removeformat',
    //toolbar2: 'bold italic underline strikethrough | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | table | undo redo | visualchars visualblocks | code fullscreen',
    //toolbar3: 'pdfPreview | vPessoas vLocais vDatas vForm',

    table_toolbar: '',

    mobile: {
        theme: 'mobile',
        plugins: ['autosave', 'lists', 'autolink'],
        toolbar: ['undo', 'bold', 'italic', 'styleselect'],
        height: '50px'
    },

    addDynamicMenu: editor => { },

    setup: editor => {
        FLOW_OBJECT_DETAILS_DISPATCH_PROCESS_TINYMCE_OPTIONS.addDynamicMenu(editor);
    }
};

export const FLOW_OBJECT_DETAILS_FINISH_CONTENT_MESSAGE_TINYMCE_OPTIONS = {
    selector: '#tinyMceFinishContentMessage',
    language: 'pt_BR',
    height: '160px',
    content_css: '/assets/css/flow-object-details-tinymce.css',
    body_class: 'tinymce-body',
    theme: 'silver',
    forced_root_block: false,
    statusbar: false,
    menubar: false,
    contextmenu: false,
    branding: false,
    fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt',

    // evita que o tinyMce altere as URLs das imagens fornecidas
    relative_urls: false,
    remove_script_host: false,
    convert_urls: false,
    default_link_target: '_blank',

    plugins: [
        'searchreplace'
    ],

    toolbar1: 'searchreplace | undo redo | vForm',
    //toolbar1: 'fontsizeselect | searchreplace | code fullscreen',
    //toolbar1: 'styleselect fontsizeselect | outdent indent | searchreplace | charmap subscript superscript | removeformat',
    //toolbar2: 'bold italic underline strikethrough | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | table | undo redo | visualchars visualblocks | code fullscreen',
    //toolbar3: 'pdfPreview | vPessoas vLocais vDatas vForm',

    table_toolbar: '',

    mobile: {
        theme: 'mobile',
        plugins: ['autosave', 'lists', 'autolink'],
        toolbar: ['undo', 'bold', 'italic', 'styleselect'],
        height: '50px'
    },

    addDynamicMenu: editor => { },

    setup: editor => {
        FLOW_OBJECT_DETAILS_FINISH_CONTENT_MESSAGE_TINYMCE_OPTIONS.addDynamicMenu(editor);
    }
};

export const FLOW_OBJECT_DETAILS_FINISH_CONTENT_WAITING_MESSAGE_TINYMCE_OPTIONS = {
    selector: '#tinyMceFinishContentWaitingMessage',
    language: 'pt_BR',
    height: '160px',
    content_css: '/assets/css/flow-object-details-tinymce.css',
    body_class: 'tinymce-body',
    theme: 'silver',
    forced_root_block: false,
    statusbar: false,
    menubar: false,
    contextmenu: false,
    branding: false,
    fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt',

    // evita que o tinyMce altere as URLs das imagens fornecidas
    relative_urls: false,
    remove_script_host: false,
    convert_urls: false,
    default_link_target: '_blank',

    plugins: [
        'searchreplace'
    ],

    toolbar1: 'searchreplace | undo redo | vForm',
    //toolbar1: 'fontsizeselect | searchreplace | code fullscreen',
    //toolbar1: 'styleselect fontsizeselect | outdent indent | searchreplace | charmap subscript superscript | removeformat',
    //toolbar2: 'bold italic underline strikethrough | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | table | undo redo | visualchars visualblocks | code fullscreen',
    //toolbar3: 'pdfPreview | vPessoas vLocais vDatas vForm',

    table_toolbar: '',

    mobile: {
        theme: 'mobile',
        plugins: ['autosave', 'lists', 'autolink'],
        toolbar: ['undo', 'bold', 'italic', 'styleselect'],
        height: '50px'
    },

    addDynamicMenu: editor => { },

    setup: editor => {
        FLOW_OBJECT_DETAILS_FINISH_CONTENT_WAITING_MESSAGE_TINYMCE_OPTIONS.addDynamicMenu(editor);
    }
};
