import { GroupingColumn } from "./grouping-column.model";
import { Guid } from "guid-typescript";

// #region [FlowObjectDefinition]
export class FlowObjectDefinition {
    id?: string = Guid.create().toString();
    name?: string;
    publicName?: string;
    configSchema?: string = null;
    publicMessageHtml?: string = null;
    formSchema?: string = null;
    documentHtmlContent?: string = null;
    version?: number = 1;
    innerIndex?: number = 0;
    outerIndex?: number = 0;
    isFlowStarter?: boolean = false;
    isFirstGatewayPath?: boolean = false;
    isSecondGatewayPath?: boolean = false;
    isLatestVersion?: boolean = true;
    flowDefinitionId?: string;
    groupingColumnId?: string;
    typeId?: FlowObjectType;
    groupingColumn?: GroupingColumn;
    next?: FlowObjectDefinition[] = [];

    public constructor(init?: Partial<FlowObjectDefinition>) {
        if (init != null && init.name == null) {
            this.name = FlowObjectTypeDescription.get(init.typeId);
        }

        if (init != null && init.publicName == null) {
            this.publicName = FlowObjectTypePublicName.get(init.typeId);
        }

        Object.assign(this, init);
    }
}

export enum FlowObjectType {
    Dummy = 0,
    StartForm = 10,
    StartInboundApi = 11,
    TaskForward = 20,
    TaskAcknowledge = 21,
    TaskMidwayOutboundApi = 22,
    TaskRegisterProcess = 23,
    TaskDispatchProcess = 24,
    GatewayApprove = 30,
    GatewayRules = 31,
    GatewayPaths = 32,
    Finish = 40,
    FinishOutboundApi = 41,
    FinishContent = 42
}

export const FlowObjectTypeClassName = new Map<number, string>([
    [FlowObjectType.StartForm, 'start-form'],
    [FlowObjectType.StartInboundApi, 'start-inbound-api'],
    [FlowObjectType.TaskForward, 'task-forward'],
    [FlowObjectType.TaskAcknowledge, 'task-acknowledge'],
    [FlowObjectType.TaskMidwayOutboundApi, 'task-midway-outbound-api'],
    [FlowObjectType.TaskRegisterProcess, 'task-register-process'],
    [FlowObjectType.TaskDispatchProcess, 'task-dispatch-process'],
    [FlowObjectType.GatewayApprove, 'gateway-approve'],
    [FlowObjectType.GatewayRules, 'gateway-rules'],
    [FlowObjectType.GatewayPaths, 'gateway-paths'],
    [FlowObjectType.FinishOutboundApi, 'finish-outbound-api'],
    [FlowObjectType.Finish, 'finish'],
    [FlowObjectType.FinishContent, 'finish-content']
]);

export const FlowObjectTypeDescription = new Map<number, string>([
    [FlowObjectType.StartForm, 'Preencher Formulário'],
    [FlowObjectType.StartInboundApi, 'Iniciar por API'],
    [FlowObjectType.TaskForward, 'Encaminhar'],
    [FlowObjectType.TaskAcknowledge, 'Dar Ciência'],
    [FlowObjectType.TaskMidwayOutboundApi, 'Comunicar com API'],
    [FlowObjectType.TaskRegisterProcess, 'Autuar Processo'],
    [FlowObjectType.TaskDispatchProcess, 'Despachar Processo'],
    [FlowObjectType.GatewayApprove, 'Aprovar'],
    [FlowObjectType.GatewayRules, 'Aplicar Regras'],
    [FlowObjectType.GatewayPaths, 'Dividir Fluxo'],
    [FlowObjectType.FinishOutboundApi, 'Enviar para API'],
    [FlowObjectType.Finish, 'Encerrar Fluxo'],
    [FlowObjectType.FinishContent, 'Encerrar com Conteúdo']
]);

export const FlowObjectTypePublicName = new Map<number, string>([
    [FlowObjectType.StartForm, 'Preenchimento do Formulário'],
    [FlowObjectType.StartInboundApi, 'Envio de Documentos'],
    [FlowObjectType.TaskForward, 'Encaminhamento do Formulário'],
    [FlowObjectType.TaskAcknowledge, 'Ciência do setor responsável'],
    [FlowObjectType.TaskMidwayOutboundApi, 'Comunicação com sistema externo'],
    [FlowObjectType.TaskRegisterProcess, 'Autuação de Processo'],
    [FlowObjectType.TaskDispatchProcess, 'Despacho de Processo'],
    [FlowObjectType.GatewayApprove, 'Aprovação do setor responsável'],
    [FlowObjectType.GatewayRules, 'Validação de resposta do sistema externo'],
    [FlowObjectType.GatewayPaths, 'Dividir Fluxo'],
    [FlowObjectType.FinishOutboundApi, 'Formulário enviado'],
    [FlowObjectType.Finish, 'Formulário enviado'],
    [FlowObjectType.FinishContent, 'Formulário enviado']
]);

export const FlowObjectTypeEDocsName = new Map<number, string>([
    [FlowObjectType.StartForm, 'Formulário']
]);

export const FlowObjectTypeCategory = new Map<number, string>([
    [FlowObjectType.StartForm, 'Preenchimento de Formulário (Início de Fluxo)'],
    [FlowObjectType.StartInboundApi, 'Início por API (Início de Fluxo)'],
    [FlowObjectType.TaskForward, 'Encaminhamento de Conteúdo'],
    [FlowObjectType.TaskAcknowledge, 'Comunicação de Conteúdo'],
    [FlowObjectType.TaskMidwayOutboundApi, 'Comunicação com API'],
    [FlowObjectType.TaskRegisterProcess, 'Autuação de Processo'],
    [FlowObjectType.TaskDispatchProcess, 'Despacho de Processo'],
    [FlowObjectType.GatewayApprove, 'Aprovação de Conteúdo'],
    [FlowObjectType.GatewayRules, 'Aplicação de Regras'],
    [FlowObjectType.GatewayPaths, 'Divisão de Fluxo'],
    [FlowObjectType.FinishOutboundApi, 'Envio para API'],
    [FlowObjectType.Finish, 'Encerramento de Fluxo'],
    [FlowObjectType.FinishContent, 'Encerramento de Fluxo']
]);

export class FormSchema {
    components?: any[] = [];
}
// #endregion
