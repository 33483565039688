export class VisibleArea {
    flowDefinitionDetails: boolean = false;
    flowDefinitionDetailsHotConfigs: boolean = false;
    flowObjectDetails: boolean = false;
    formBuilder: boolean = false;
    formBuilderInboundApi: boolean = false;
    htmlEditor: boolean = false;
    api: boolean = false;
    midApi: boolean = false;
    gatewayRules: boolean = false;
    gatewayPaths: boolean = false;
    finishContent: boolean = false;
}
