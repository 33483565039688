import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ElementRef,
    ViewChild,
    AfterViewInit,
    ViewChildren,
    QueryList
} from '@angular/core';
import {
    FlowObjectDefinition,
    FlowObjectType,
    FlowObjectTypeClassName
} from '../../../models/flow-object.model';
import {
    Grouping,
    GroupingType,
    GroupingTypeClassName
} from '../../../models/grouping.model';
import { CdkDragStart } from '@angular/cdk/drag-drop';
import { GroupingColumn } from '../../../models/grouping-column.model';

@Component({
    selector: 'selected-grouping',
    templateUrl: './selected-grouping.component.html',
    styleUrls: ['./selected-grouping.component.scss']
})
export class SelectedGroupingComponent implements OnInit, AfterViewInit {
    @ViewChild('contextModeRef') contextModeRef: ElementRef<HTMLElement>;
    @ViewChild('flowObjectBodyContainerRef') flowObjectBodyContainerRef: ElementRef<HTMLElement>;
    @ViewChildren('selectedFlowObjectRef') selectedFlowObjectRef: QueryList<ElementRef>;

    FlowObjectType: typeof FlowObjectType = FlowObjectType;
    FlowObjectTypeClassName: typeof FlowObjectTypeClassName = FlowObjectTypeClassName;
    GroupingTypeClassName: typeof GroupingTypeClassName = GroupingTypeClassName;

    @Input() inputModel: Grouping;
    @Input() inputIsReadOnlyMode: boolean;
    @Input() inputHasGatewayPath: boolean;
    @Input() inputSelectedGroupings: Grouping[];
    @Input() inputSelectedGroupingColumns: GroupingColumn[];
    @Input() inputSelectedFlowObjects: FlowObjectDefinition[];
    @Output() outputToggleEditAreaEvent = new EventEmitter<FlowObjectDefinition>();
    @Output() outputRemoveGroupingEvent = new EventEmitter<{ event: MouseEvent, flowObject: FlowObjectDefinition }>();
    @Output() outputChangeTaskTypeEvent = new EventEmitter<{ event: MouseEvent, flowObject: FlowObjectDefinition }>();
    @Output() outputMoveGroupingBackwardsEvent = new EventEmitter<{ event: MouseEvent, flowObject: FlowObjectDefinition }>();
    @Output() outputMoveGroupingForwardsEvent = new EventEmitter<{ event: MouseEvent, flowObject: FlowObjectDefinition }>();
    @Output() outputMoveGroupingUpwardsEvent = new EventEmitter<{ event: MouseEvent, flowObject: FlowObjectDefinition }>();
    @Output() outputMoveGroupingDownwardsEvent = new EventEmitter<{ event: MouseEvent, flowObject: FlowObjectDefinition }>();
    @Output() outputNotifyFlowObjectLoadedEvent = new EventEmitter<ElementRef[]>();
    @Output() outputDragStartedEvent = new EventEmitter<CdkDragStart>();

    constructor(public element: ElementRef) { }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() { }

    ngAfterViewInit() {
        this.notifyFlowObjectLoaded();
    }

    // ======================
    // public methods
    // ======================

    isOverflown(elem: HTMLElement): boolean {
        let flowObjectBodyContainer = this.flowObjectBodyContainerRef?.nativeElement;
        if (flowObjectBodyContainer == null) return false;

        // compensação por um fator de ajuste empírico
        return flowObjectBodyContainer.scrollWidth > elem.clientWidth * .87;
    }

    canShowEditButton(flowObject: FlowObjectDefinition): boolean {
        return !this.inputIsReadOnlyMode && flowObject.typeId != FlowObjectType.Dummy;
    }

    canShowRemoveButton(flowObject: FlowObjectDefinition): boolean {
        return !this.inputIsReadOnlyMode
            && flowObject.groupingColumn.innerIndex == 0
            && flowObject.typeId != FlowObjectType.Dummy;
    }

    canShowChangeButton(flowObject: FlowObjectDefinition): boolean {
        return !this.inputIsReadOnlyMode
            && this.inputModel.typeId == GroupingType.Gateway
            && flowObject.innerIndex == 0
            && flowObject.groupingColumn.innerIndex == 1
            && flowObject.typeId != FlowObjectType.Dummy;
    }

    canShowHorizontalMoveButton(flowObject: FlowObjectDefinition): boolean {
        return !this.inputIsReadOnlyMode
            && flowObject.groupingColumn.innerIndex == 0
            && flowObject.typeId != FlowObjectType.GatewayPaths;
    }

    canShowMoveForwardsButton(flowObject: FlowObjectDefinition): boolean {
        let outerIndex = flowObject.groupingColumn.grouping.outerIndex;
        let mainPathLastIndex = this.inputSelectedGroupings.filter(x => !x.isFirstGatewayPath && !x.isSecondGatewayPath).length - 1
            - (this.inputHasGatewayPath ? 1 : 0);
        let firstGatewayPathLastIndex = this.inputSelectedGroupings.filter(x => !x.isFirstGatewayPath && !x.isSecondGatewayPath).length
            + this.inputSelectedGroupings.filter(x => x.isFirstGatewayPath).length - 1;
        let secondGatewayPathLastIndex = firstGatewayPathLastIndex + this.inputSelectedGroupings.filter(x => x.isSecondGatewayPath).length;

        return (
                outerIndex != mainPathLastIndex
                && !this.inputHasGatewayPath
            ) || (
                outerIndex != mainPathLastIndex
                && this.inputHasGatewayPath
                && outerIndex != firstGatewayPathLastIndex
                && outerIndex != secondGatewayPathLastIndex
            );
    }

    canShowMoveBackwardsButton(flowObject: FlowObjectDefinition): boolean {
        let outerIndex = flowObject.groupingColumn.grouping.outerIndex;
        let firstGatewayPathFirstIndex = this.inputSelectedGroupings.filter(x => !x.isFirstGatewayPath && !x.isSecondGatewayPath).length;
        let secondGatewayPathFirstIndex = firstGatewayPathFirstIndex + this.inputSelectedGroupings.filter(x => x.isFirstGatewayPath).length;

        return (
                outerIndex != 0
                && !this.inputHasGatewayPath
            ) || (
                outerIndex != 0
                && this.inputHasGatewayPath
                && outerIndex != firstGatewayPathFirstIndex
                && outerIndex != secondGatewayPathFirstIndex
            );
    }

    canShowVerticalMoveButton(flowObject: FlowObjectDefinition): boolean {
        return !this.inputIsReadOnlyMode
            && flowObject.groupingColumn.innerIndex == 0
            && flowObject.typeId != FlowObjectType.GatewayPaths
            && flowObject.typeId != FlowObjectType.Dummy
            && this.inputHasGatewayPath;
    }

    canShowMoveUpwardsButton(flowObject: FlowObjectDefinition): boolean {
        return flowObject.isFirstGatewayPath
            || flowObject.isSecondGatewayPath;
    }

    canShowMoveDownwardsButton(flowObject: FlowObjectDefinition): boolean {
        return !flowObject.isSecondGatewayPath;
    }

    toggleEditArea(flowObject: FlowObjectDefinition) {
        if (flowObject.typeId == FlowObjectType.Dummy) return;

        this.outputToggleEditAreaEvent.emit(flowObject);
    }

    removeGrouping(event: MouseEvent, flowObject: FlowObjectDefinition) {
        this.outputRemoveGroupingEvent.emit({ event, flowObject });
    }

    changeTaskType(event: MouseEvent, flowObject: FlowObjectDefinition) {
        this.outputChangeTaskTypeEvent.emit({ event, flowObject });
    }

    moveGroupingBackwards(event: MouseEvent, flowObject: FlowObjectDefinition) {
        this.outputMoveGroupingBackwardsEvent.emit({ event, flowObject });
    }

    moveGroupingForwards(event: MouseEvent, flowObject: FlowObjectDefinition) {
        this.outputMoveGroupingForwardsEvent.emit({ event, flowObject });
    }

    moveGroupingUpwards(event: MouseEvent, flowObject: FlowObjectDefinition) {
        this.outputMoveGroupingUpwardsEvent.emit({ event, flowObject });
    }

    moveGroupingDownwards(event: MouseEvent, flowObject: FlowObjectDefinition) {
        this.outputMoveGroupingDownwardsEvent.emit({ event, flowObject });
    }

    notifyFlowObjectLoaded() {
        this.outputNotifyFlowObjectLoadedEvent.emit(this.selectedFlowObjectRef.toArray());
    }

    dragStarted(event: CdkDragStart) {
        this.outputDragStartedEvent.emit(event);
    }

    // ======================
    // private methods
    // ======================
}
