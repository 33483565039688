import { CdkDragEnd, CdkDragMove, CdkDragStart } from '@angular/cdk/drag-drop';
import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { FlowObjectDefinition, FlowObjectType, FlowObjectTypeClassName } from '../../../models/flow-object.model';
import { NgxMatPopoverComponent } from '../../ngx-mat-popover/ngx-mat-popover.component';

@Component({
    selector: 'available-flow-object',
    templateUrl: './available-flow-object.component.html',
    styleUrls: ['./available-flow-object.component.scss']
})
export class AvailableFlowObjectComponent implements OnInit {
    // #region [ViewChild]
    @ViewChild('contextModeRef') contextModeRef: ElementRef<HTMLElement>;
    @ViewChild('flowObjectBodyContainerRef') flowObjectBodyContainerRef: ElementRef<HTMLElement>;
    @ViewChild('actionsMatPopoverRef') actionsMatPopoverRef: NgxMatPopoverComponent;
    // #endregion

    // #region [Type properties]
    FlowObjectType: typeof FlowObjectType = FlowObjectType;
    FlowObjectTypeClassName: typeof FlowObjectTypeClassName = FlowObjectTypeClassName;
    // #endregion

    // #region [getters]
    get isUniqueTaskDisabled(): boolean {
        return this.inputHasGatewayPath && this.inputModel.typeId == FlowObjectType.GatewayPaths
            || this.inputHasFlowStarter && this.isStartingTask;
    }
    get isStartingTask(): boolean {
        return [
            FlowObjectType.StartForm,
            FlowObjectType.StartInboundApi
        ].includes(this.inputModel.typeId);
    }
    get isFinishingTask(): boolean {
        return [
            FlowObjectType.Finish,
            FlowObjectType.FinishOutboundApi,
            FlowObjectType.FinishContent
        ].includes(this.inputModel.typeId);
    }
    get isGatewayTask(): boolean {
        return [
            FlowObjectType.GatewayApprove,
            FlowObjectType.GatewayRules,
            FlowObjectType.GatewayPaths
        ].includes(this.inputModel.typeId);
    }
    get isGeneralTask(): boolean {
        return !this.isStartingTask
            && !this.isFinishingTask
            && !this.isGatewayTask;
    }
    // #endregion

    // #region [Input/Output]
    @Input() inputModel: FlowObjectDefinition;
    @Input() inputIsReadOnlyMode: boolean;
    @Input() inputHasGatewayPath: boolean;
    @Input() inputHasFlowStarter: boolean;
    @Input() inputFirstGatewayPathName: string;
    @Input() inputSecondGatewayPathName: string;
    @Output() outputSelectFlowObjectEvent = new EventEmitter<{ flowObject: FlowObjectDefinition, isFirstGatewayPath: boolean, isSecondGatewayPath: boolean }>();
    @Output() outputDragStartedEvent = new EventEmitter<{ event: CdkDragStart, isAvailableFlowObject: boolean }>();
    @Output() outputDragEndedEvent = new EventEmitter<CdkDragEnd>();
    @Output() outputDragMovedEvent = new EventEmitter<CdkDragMove>();
    // #endregion

    constructor(public element: ElementRef) { }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() { }

    // ======================
    // public methods
    // ======================

    isOverflown(elem: HTMLElement): boolean {
        let flowObjectBodyContainer = this.flowObjectBodyContainerRef?.nativeElement;
        if (flowObjectBodyContainer == null) return false;

        // compensação por um fator de ajuste empírico
        return flowObjectBodyContainer.scrollWidth > elem.clientWidth * .87;
    }

    selectFlowObject(isFirstGatewayPath: boolean = false, isSecondGatewayPath: boolean = false) {
        this.outputSelectFlowObjectEvent.emit({
            flowObject: this.inputModel,
            isFirstGatewayPath: isFirstGatewayPath,
            isSecondGatewayPath: isSecondGatewayPath
        });

        this.actionsMatPopoverRef.close();
    }

    dragStarted(event: CdkDragStart, isAvailableFlowObject: boolean = false) {
        this.outputDragStartedEvent.emit({ event, isAvailableFlowObject });
    }

    dragEnded(event: CdkDragEnd) {
        this.outputDragEndedEvent.emit(event);
    }

    dragMoved(event: CdkDragMove) {
        this.outputDragMovedEvent.emit(event);
    }

    clickEvent() {
        if (this.isUniqueTaskDisabled) return;

        if (this.inputHasGatewayPath) {
            this.actionsMatPopoverRef.open();
        } else {
            this.selectFlowObject();
        }
    }

    // ======================
    // private methods
    // ======================
}
