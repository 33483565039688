"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = [{
  key: 'inputMask',
  ignore: true
}, {
  key: 'allowMultipleMasks',
  ignore: true
}, {
  key: 'showWordCount',
  ignore: true
}, {
  key: 'showCharCount',
  ignore: true
}, {
  type: 'textfield',
  input: true,
  weight: 310,
  key: 'prefix',
  label: 'prefix',
  tooltip: 'Specify the prefix symbol after the component (e.g.: USD, EUR)'
}, {
  type: 'textfield',
  input: true,
  weight: 320,
  key: 'suffix',
  label: 'suffix',
  tooltip: 'Specify the suffix symbol after the component (e.g.: USD, EUR).'
}];
exports.default = _default;